<template>
  <div class="contents-section">
    <div class="title-section">
      <h2 class="d-flex align-start">
        게시판관리
        <v-tooltip right color="black">
          <template #activator="{ on, attrs }">
            <v-btn
              icon class="mt-1 ml-1"
              v-bind="attrs" v-on="on"
              @click="getList"
              color="primary"
              :loading="loading"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>새로고침</span>
        </v-tooltip>
      </h2>
      <v-btn
        depressed
        color="primary"
        @click="dialog.show = true"
      >
        게시판생성
      </v-btn>
    </div>
    <v-progress-linear
      v-if="loading === true"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <v-simple-table class="mt-10" v-else-if="loading === false && list.length > 0">
      <template #default>
        <tbody>
          <tr
            v-for="item in list"
            :key="item.no"
          >
            <td>
              {{ item.name }}
            </td>
            <td class="text-right">
              <v-btn
                depressed
                color="error"
                class="ml-3"
                @click="deleteConfirmOpen(item.no, item.name)"
              >삭제</v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="empty-msg"
      v-else-if="loading === false && list.length === 0"
    >등록된 게시판이 없습니다.</div>
    <v-dialog
      v-model="dialog.show"
      scrollable
      max-width="400"
      @click:outside="init"
    >
      <v-card>
        <v-card-title
          class="d-flex align-center pt-6"
        >
          <span class="text-h5">게시판생성</span>
          <v-tooltip top color="black">
            <template #activator="{ on, attrs }">
              <v-icon
                class="mb-6"
                small
                color="warning"
                v-on="on"
                v-bind="attrs"
              >mdi-help-circle-outline</v-icon>
            </template>
            <span>
              생성된 게시판은 계정에 권한을 부여한 후 이용할 수 있습니다.
              <br>
              ※ 직원관리-권한설정에서 부여
            </span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <v-text-field
            class="pb-0 pt-4"
            v-model="dialog.title"
            solo
            outlined
            dense
            flat
            placeholder="게시판이름"
            :rules="[titleRule]"
            autofocus
          />
        </v-card-text>
        <v-card-actions class="pb-6 pr-6 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            @click="registBoard"
            :disabled="dialog.title.length === 0 || dialog.title.length > 20"
          >
            생성
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'Board',
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  data: () => ({
    list: [],
    updateName: '',
    dialog: {
      show: false,
      title: '',
    },
    titleRule: (value) => {
      const test = value.length < 20 && value.length > 0;
      return test || '게시판이름은 1~20자여야 합니다.';
    },
    loading: false,
  }),
  methods: {
    ...mapMutations({
      confirm: 'dialog/confirm',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    init() {
      this.dialog.show = false;
      this.dialog.title = '';
    },
    registBoard() {
      if (this.dialog.title !== '공지사항') {
        this.$socket.emit('board.add', { name: this.dialog.title }, (resp) => {
          if (resp.result === 'success') {
            this.alert(['info', '게시판이 생성되었습니다.']);
            this.init();
            this.getList();
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
          }
        });
      } else {
        this.alert(['error', '\'공지사항\'은 기본 게시판입니다. 다른 이름을 입력해주세요.']);
      }
    },
    getList() {
      this.loading = true;
      this.$socket.emit('board.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: [],
        sortDesc: [],
        filters: [
          {
            condition: 'ne',
            column: 'no',
            value: 0,
          },
        ],
      }, (resp) => {
        if (resp.result === 'success') {
          this.list = resp.items;
          this.loading = false;
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.loading = false;
        }
      });
    },
    updateBoard(no) {
      if (this.updateName.length <= 0 || this.updateName.length > 20) {
        this.alert(['error', '게시판이름은 1~20자여야 합니다.']);
      } else {
        this.$socket.emit('board.update', { no, name: this.updateName }, (resp) => {
          if (resp.result === 'success') {
            this.alert(['info', '수정되었습니다.']);
            this.getList();
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
          }
        });
      }
    },
    deleteConfirmOpen(no, name) {
      const refresh = () => {
        this.getList();
      };
      const func = () => {
        this.alert(['info', '삭제되었습니다.']);
        this.confirm({ show: false });
        this.$socket.emit('board.delete', { no }, (resp) => {
          if (resp.result === 'success') {
            this.confirm({ show: false });
            this.alert(['info', '삭제되었습니다.']);
            refresh();
          } else {
            this.confirm({ show: false });
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
          }
        });
      };
      this.confirm({
        show: true,
        func,
        msg: `${name} 게시판을 정말 삭제할까요?`,
        btnText: '삭제',
        color: 'error',
      });
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.getList();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.text-input {
  background-color: #fff;
  padding: 5px;
  padding-right: 8px;
  padding-top:2px;
  width: 280px;
  display: flex;
  align-items: center;
}
.empty-msg {
  padding: 20px 20px;
  background-color: #f2f2f2;
  border-radius: 4px;
}
</style>
